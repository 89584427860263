import { useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { gsap } from 'gsap';
import steps from '../../assets/steps.png';
import bages from '../../assets/bages.png';
import './TradeToday.scss';

const TradeToday = () => {
    const { t } = useTranslation()
    const ref = useRef(null);
    var screenWidth = document.documentElement.clientWidth;


    /**
     *          ========     BAGES       ========
    */

    // START

    useEffect(() => {
        const element = ref.current;

        if (screenWidth >= 1281) {
            gsap.fromTo(
                element.querySelector('#bages'),
                {
                    opacity: 0,
                    scale: 0.8,
                    y: -700,
                    x: -100,
                },
                {
                    opacity: 1,
                    y: 0,
                    x: 0,
                    scale: 1,
                    duration: 1,
                    ease: "none", 
                    scrollTrigger: {
                        trigger: element.querySelector(".header"),
                        start: 6000,
                        end: 6300,
                        scrub: true
                    },
                }
            );
        }


        if (screenWidth >= 1025 && screenWidth <= 1280) {
            gsap.fromTo(
                element.querySelector('#bages'),
                {
                    opacity: 0,
                    scale: 0.8,
                    y: -700,
                    x: -100,
                },
                {
                    opacity: 1,
                    y: 0,
                    x: 0,
                    scale: 1,
                    duration: 1,
                    ease: "none",
                    scrollTrigger: {
                        trigger: element.querySelector(".tt-container-content-steps"),
                        start: "center bottom",
                        end: "top center",
                        scrub: true
                    },
                }
            );
        }

        if (screenWidth <= 1024 && screenWidth >= 952) {
            gsap.fromTo(
                element.querySelector('#bages'),
                {
                    opacity: 0,
                    scale: 0.8,
                    y: -700,
                    x: -100,
                },
                {
                    opacity: 1,
                    y: 0,
                    x: 0,
                    scale: 1,
                    duration: 1,
                    ease: "none",
                    scrollTrigger: {
                        trigger: element.querySelector(".tt-container-content-steps"), 
                        start: 7599,
                    },
                }
            );
        }
        // eslint-disable-next-line
    }, []);

    return (
        <div className='tt-container' ref={ref}>
            <div className='header h-1'>
                {t('trade_today_title')}
            </div>

            <div className='tt-container-content'>
                <div className='tt-container-content-steps'>
                    <div className='steps-image'>
                        <img src={steps} alt='steps' />
                    </div>
                    <div className='steps-text'>
                        <div className='steps-text-par'>
                            <div className='steps-text-par-header content-3'>{t('sign_up_now')}</div>
                            <div className='steps-text-par-desc content-1'>{t('trade_today_step_1')}</div>
                        </div>
                        <div className='steps-text-par'>
                            <div className='steps-text-par-header content-3'>{t('make_deposit')}</div>
                            <div className='steps-text-par-desc content-1'>{t('trade_today_step_2')}</div>
                        </div>
                        <div className='steps-text-par'>
                            <div className='steps-text-par-header content-3'>{t('start_trading_session')}</div>
                            <div className='steps-text-par-desc content-1'>{t('trade_today_step_3')}</div>
                        </div>
                    </div>
                </div>

                <div className='tt-container-content-bages'>
                    <img id='bages' src={bages} alt='bages' />
                </div>
            </div>

            <div className='tt-container-footer'>
                <div className='text content-5'>
                    {t('welcome_universe_trader')}
                </div>
                <a href='https://myaccount.pixpal.tech/signup' className='decoration-none tt-container-footer-button content-1 btn-anim-dark'>
                    {t('open_live')}
                </a>
            </div>
        </div>
    );
};

export default TradeToday;
