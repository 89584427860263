import { useTranslation } from 'react-i18next';
import Dropdown from 'react-dropdown';
import './Search.scss';

const regions = [
    { value: 'all', label: 'All' },
    { value: 'stocks-american', label: 'Stocks-American' },
    { value: 'stocks-arabic', label: 'Stocks-Arabic' },
    { value: 'stocks-european', label: 'Stocks-European' },
    { value: 'stocks-russian', label: 'Stocks-Russian' },
    { value: 'stocks-asian', label: 'Stocks-Asian' },
];
const defaultOption = regions[0].value;

const Search = ({ nameFilter, setNameFilter, allFilter, setRegionFilter, setAllFilter }) => {
    const { t } = useTranslation();
    return (
        <div className='search-container'>
            <div className='search-container-search'>
                <div className='search-container-search-text search-col'>
                    {t('report')}
                </div>
                <div className='search-container-search-input search-col'>
                    <input
                        type='text'
                        placeholder={t('search_for_names')}
                        value={nameFilter}
                        onChange={(event) => setNameFilter(event.target.value)}
                    />
                    <Dropdown
                        className='dropdown-component'
                        placeholderClassName='dropdown' 
                        options={regions} 
                        onChange={(event) => setRegionFilter(event.value)} 
                        value={defaultOption} 
                    />
                </div>
            </div>
            <div className='search-container-select'>
                <div 
                    className='search-container-select-item'
                    onClick={() => setAllFilter(true)}
                >
                    <div 
                        className={`search-container-select-item-text ${allFilter && 'sel-all-text'}`}
                    >{t('all')}</div>
                    <div 
                        className={`search-container-select-item-line ${allFilter && 'sel-all-line'}`}></div>
                </div>  
                <div 
                    className='search-container-select-item'
                    onClick={() => setAllFilter(false)}
                >
                    <div
                        className={`search-container-select-item-text ${!allFilter && 'sel-all-text'}`}
                    >{t('this_week')}</div>
                    <div className={`search-container-select-item-line ${!allFilter && 'sel-all-line'}`}></div>
                </div> 
            </div>
        </div>
    );
};

export default Search;
