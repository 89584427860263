import { useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { gsap } from 'gsap';
import Cookies from 'js-cookie';
import charts from '../../assets/charts-bg.png';
import './TradeWithTight.scss';

const TradeWithTight = () => {
    const ref = useRef(null);
    var screenWidth = document.documentElement.clientWidth;
    const { t } = useTranslation();
    const lang = Cookies.get('i18next');

    /**
     *          ========     CHARTS       ========
    */

    // ENTER

    useEffect(() => {
        const element = ref.current;

        if (screenWidth >= 1025) {
            gsap.fromTo(
                element.querySelector('#charts'),
                {
                    opacity: 0,
                    scale: 0.8,
                    y: -700,
                    x: -100,
                },
                {
                    opacity: 1,
                    y: -100,
                    x: 0,
                    scale: 1,
                    duration: 1,
                    ease: "none",
                    scrollTrigger: {
                        trigger: element.querySelector(".header"),
                        start: "top bottom",
                        end: "top center",
                        scrub: true,
                    },
                }
            );
        }

        if (screenWidth <= 1024 && screenWidth >= 481) {
            gsap.fromTo(
                element.querySelector('#charts'),
                {
                    opacity: 0,
                    scale: 0.8,
                    y: -700,
                    x: 0,
                },
                {
                    opacity: 1,
                    y: 0,
                    x: 0,
                    scale: 1,
                    duration: 1,
                    ease: "none",
                    scrollTrigger: {
                        trigger: element.querySelector(".twt-container-content-charts"), 
                        start: 2799,
                    },
                }
            );
        }

        if (screenWidth <= 480) {
            gsap.fromTo(
                element.querySelector('#charts'),
                {
                    opacity: 0,
                    scale: 0.8,
                    y: -700,
                    x: 0,
                },
                {
                    opacity: 1,
                    y: 0,
                    x: 0,
                    scale: 1,
                    duration: 1,
                    ease: "none",
                    scrollTrigger: {
                        trigger: element.querySelector(".twt-container-content-charts"), 
                        start: 2699,
                    },
                }
            );
        }
        // eslint-disable-next-line
    }, []);

    // EXIT

    useEffect(() => {
        const element = ref.current;

        if (screenWidth >= 1025) {

            gsap.fromTo(
                element.querySelector('#charts'),
                {
                    opacity: 1,
                    y: -100,
                    x: 0,
                    scale: 1,
                },
                {
                    opacity: 0,
                    y: 0,
                    x: 700,
                    scale: 1,
                    duration: 1,
                    ease: "none",
                    scrollTrigger: {
                        trigger: element.querySelector(".open-live-account"),
                        start: "top center",
                        end: "top top",
                        scrub: true
                    },
                }
                );
            }
            // eslint-disable-next-line
        }, []);

    return (
        <div className='twt-container' ref={ref}>

            <div className={`header ${lang !== 'en' ? 'header-anlang' : ''} h-1`}>
                {t('trade_tight_title')}
            </div>

            <div className='twt-container-content'>
                <div className='twt-container-content-info'>
                    <div className='description content-1'>
                        {t('trade_tight_text_1')}
                    </div>
                    <div className='second content-3'>
                        {t('trade_tight_text_2')}
                    </div>
                    <a href='https://myaccount.pixpal.tech/signup' className='decoration-none open-live-account unselectable btn-anim-dark'>{t('open_live')}</a>
                </div>

                <div className='twt-container-content-charts'>
                    <img id='charts' src={charts} alt='charts' />
                </div>
            </div>

        </div>
    );
};

export default TradeWithTight;
