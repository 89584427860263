import { useTranslation } from "react-i18next";
import './BannerComponent.scss';
import { gsap } from 'gsap';
import CompanyBanner from '../../../assets/company-support/grid_bg.png'
import icon1 from '../../../assets/company-support/icon-1.png'
import icon2 from '../../../assets/company-support/icon-2.png'
import icon3 from '../../../assets/company-support/icon-3.png'
import icon4 from '../../../assets/company-support/icon-4.png'
import icon5 from '../../../assets/company-support/icon-5.png'
import icon6 from '../../../assets/company-support/icon-6.png'
import { useRef } from "react";
import { useEffect } from "react";

const BannerComponent = () => {
  const { t } = useTranslation();
  const ref = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      document.querySelector('.icon-1'),
      { opacity: 1 },
      {
        opacity: 0.1,
        duration: .5,
        ease: 'linear',
        repeat: -1,
        repeatDelay: .5,
        yoyo: true,
      }
    );
    gsap.fromTo(
      document.querySelector('.icon-2'),
      { opacity: 1 },
      {
        opacity: 0.1,
        duration: .5,
        ease: 'linear',
        repeat: -1,
        repeatDelay: .5,
        yoyo: true,
      }
    );
    gsap.fromTo(
      document.querySelector('.icon-3'),
      { opacity: 1 },
      {
        opacity: 0.1,
        duration: .5,
        ease: 'linear',
        repeat: -1,
        repeatDelay: .5,
        yoyo: true,
      }
    );

    gsap.fromTo(
      document.querySelector('.icon-4'),
      { opacity: 0.1 },
      {
        opacity: 1,
        duration: .5,
        ease: 'linear',
        repeat: -1,
        repeatDelay: .5,
        yoyo: true,
      }
    );
    gsap.fromTo(
      document.querySelector('.icon-5'),
      { opacity: 0.1 },
      {
        opacity: 1,
        duration: .5,
        ease: 'linear',
        repeat: -1,
        repeatDelay: .5,
        yoyo: true,
      }
    );
    gsap.fromTo(
      document.querySelector('.icon-6'),
      { opacity: 0.1 },
      {
        opacity: 1,
        duration: .5,
        ease: 'linear',
        repeat: -1,
        repeatDelay: .5,
        yoyo: true,
      }
    );
  }, []);

  return (
    <div className="banner__wrap" ref={ref}>
      <div className="col-md-6 col-12">
        <h1 className="banner__title">
          {t('company_support')}
        </h1>
        <p className="banner_text">{t('company_banner_text')}</p>
        <a href="https://myaccount.pixpal.tech/signup" className="banner__link btn-anim-dark">{t('open_account')}</a>
      </div>
      <div className="col-md-6 col-12 d-md-block d-none">
        <div className="banner-component-img__wrap">
          <div className="banner-component__images">
            <img src={CompanyBanner} className="base-bg" alt=""/>
            <img src={icon1} className="icon-1 icon" alt=""/>
            <img src={icon2} className="icon-2 icon" alt=""/>
            <img src={icon3} className="icon-3 icon" alt=""/>
            <img src={icon4} className="icon-4 icon" alt=""/>
            <img src={icon5} className="icon-5 icon" alt=""/>
            <img src={icon6} className="icon-6 icon" alt=""/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerComponent;
