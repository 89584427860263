import { useTranslation } from "react-i18next";
import * as Scroll from 'react-scroll';
import arrow from '../../assets/arrow_tabs.png';
import arrow_white from '../../assets/arrow_tabs_white.png';
import './FundamentalAnalysis.scss';

const Element = Scroll.Element;
const FundamentalAnalysis = ({ selectedTab, setSelectedTab }) => {
    const { t } = useTranslation()
    return (
        <div className='fa-container'>
            <Element name='fund_analysis'>
                <div className='fa-container-header h-1'>
                    {t('et_start_table_3')}
                </div>
            </Element>
            <div className='fa-container-content'>
                <div className='fa-container-content-text content-1'>
                    {t('et_start_table_1_text_7')}
                </div>
                <div className='fa-container-content-tabs'>
                    <div
                        className={`tab ${selectedTab === 1 ? 'selected-tab' : ''}`}
                        onClick={() => setSelectedTab(1)}
                    >
                        <div className='tab-header'>
                                <div className='tab-header-text'>
                                    {t('et_start_table_4')}
                                </div>
                            <div className='tab-header-arrow'>
                                <img
                                    src={selectedTab === 1 ? arrow_white : arrow}
                                    alt='arrow'
                                    // className={`${selectedTab === 1 ? 'rotate-90' : ''}`}
                                />
                            </div>
                        </div>
                        <div className={`tab-content ${selectedTab === 1 ? 'show-content' : ''}`}>
                            <div className='par'>
                                {t('et_start_table_1_text_8')}
                            </div>
                            <ul>
                                <li>{t('et_start_table_1_item_1')}</li>
                                <li>{t('et_start_table_1_item_2')}</li>
                                <li>{t('et_start_table_1_item_3')}</li>
                                <li>{t('et_start_table_1_item_4')}</li>
                                <li>{t('et_start_table_1_item_5')}</li>
                                <li>{t('et_start_table_1_item_6')}</li>
                                <li>{t('et_start_table_1_item_7')}</li>
                            </ul>
                            <div className='par'>
                                {t('et_start_table_1_text_9')}
                            </div>
                            <div className='par'>
                                {t('et_start_table_1_text_10')}
                            </div>
                        </div>
                    </div>

                    <div
                        className={`tab ${selectedTab === 2 ? 'selected-tab' : ''}`}
                        onClick={() => setSelectedTab(2)}
                    >
                        <div className='tab-header'>
                                <div className='tab-header-text'>
                                    {t('et_start_table_5')}
                                </div>
                            <div className='tab-header-arrow'>
                                <img
                                    src={selectedTab === 2 ? arrow_white : arrow}
                                    alt='arrow'
                                />
                            </div>
                        </div>
                        <div className={`tab-content ${selectedTab === 2 ? 'show-content' : ''}`}>
                            <div className='par'>
                                {t('et_start_table_1_text_12')}
                            </div>
                            <div className='par'>
                                {t('et_start_table_1_text_13')}
                            </div>
                            <div className='par'>
                                {t('et_start_table_1_text_14')}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default FundamentalAnalysis;
