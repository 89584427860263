import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import GetStarted from '../GetStarted/GetStarted';
import Tab from './Tab';
import './TabsCFDS.scss';

const TabsCFDS = ({ content }) => {
    const { t } = useTranslation();
    const [ selectedTab, setSelectedTab ] = useState(1);
    const tabs = [1, 2, 3, 4];
    return (
        <div className='tabs-cfds-container'>
            {content === 'get_started' &&
                <GetStarted />
            }
            <div className='h-1 tabs-cfds-container-header'>{t('trading_products')}</div>
            <div className='tabs'>
                {tabs.map((tab) => 
                    <Tab selectedTab={selectedTab} setSelectedTab={setSelectedTab} tab={tab} key={tab} />
                )}
            </div>
        </div>
    );
};

export default TabsCFDS;
