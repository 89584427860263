import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AffiliateProgram from '../../components/AffiliateProgram/AffiliateProgram';
import APStartScreen from '../../components/APStartScreen/APStartScreen';
import Footer from '../../components/Footer/Footer';
import NavBar from '../../components/NavBar/NavBar';
import NavBarMobile from '../../components/NavBarMobile/NavBarMobile';
import StandsFor from '../../components/StandsFor/StandsFor';
import TradeToday02 from '../../components/TradeToday02/TradeToday02';
import './AffiliatesAndPartners.scss';

const AffiliatesAndPartners = () => {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0,0);
    }, [pathname]);
    return (
        <div className='wrapper'>
          <div className='ap-bg'>
            <div className='nb-desktop'>
              <NavBar selPage={'AffiliatesAndParthners'} /> 
            </div>
            <div className='nb-mobile'>
              <NavBarMobile selPage={'AffiliatesAndParthners'} />
            </div>
            <APStartScreen />
          </div>
          <AffiliateProgram />
          <StandsFor />
          <TradeToday02 />
          <Footer />
        </div>
    );
};

export default AffiliatesAndPartners;
