import {useTranslation} from "react-i18next";
import './DifferBrokersComponent.scss';

const DifferBrokersComponent = () => {
  const { t } = useTranslation()

  return (
    <div className="different-brokers__container">
      <h2 className="different-brokers__title">{t('different_brok_title')}</h2>
      <div className="row">
        <div className="col-6">
          <div className="pr-70">
            <p className="different-brokers__text white">{t('different_brok_item_1')}</p>
            <p className="different-brokers__text grey">{t('different_brok_item_2')}</p>
            <p className="different-brokers__text subtitle">{t('different_brok_item_3')}</p>
            <p className="different-brokers__text grey">{t('different_brok_item_4')}</p>
          </div>
        </div>
        <div className="col-6 d-flex justify-content-center">
          <div className="">
            <p className="different-brokers__text grey">{t('different_brok_item_5')}</p>
            <p className="different-brokers__text white">{t('different_brok_item_6')}</p>
            <a href="https://myaccount.pixpal.tech/signup" className="banner__link btn-anim-dark">{t('open_account')}</a>
          </div>
          </div>
      </div>
    </div>
  );
};

export default DifferBrokersComponent;
