import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import logo from '../../assets/pixpal_logo.png';
import btc from '../../assets/footer/btc.png';
import mastercard from '../../assets/footer/mastercard.png';
import visa from '../../assets/footer/visa.png';
import wire_transfer from '../../assets/footer/wire_transfer.png';
import { RouteNames } from '../../router';
import './Footer.scss';

const Footer = ({ styles }) => {
    const { t } = useTranslation();
    const router = useNavigate();
    const [ inputEmail, setInputEmail ] = useState('');
    const sendEmail = () => {
        setInputEmail('');
    }
    return (
        <div className={`footer-container ${styles}`}>
            <div className='footer-container-links'>
                <div className='links-docs'>
                    <div className='links-docs-logo' >
                        <img src={logo} alt='logo' onClick={() => router(RouteNames.MAIN)} />
                    </div>
                    <div className='footer-container-footer-notice'>
                        <div className='notice-text content-1'>
                            {t('footer_text_1')}
                        </div>

                        <div className='notice-send content-1'>
                            <div className='notice-send-input'>
                                <input
                                    className='input content-1'
                                    type='text'
                                    placeholder={t('email_address')}
                                    value={inputEmail}
                                    onChange={(event) => setInputEmail(event.target.value)}
                                />
                            </div>
                            <div
                                className='notice-send-button btn-anim'
                                onClick={() => sendEmail(inputEmail)}
                            >
                                {t('send_mail')}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='links-other'>
                    <div className='links-other-relation'>
                        <a href={'/docs/en-client-agreement.pdf'} className='decoration-none sent content-1'>{t('legal_documents')}</a>
                        <a href={'/docs/en-licence.pdf'} className='decoration-none sent content-1'>{t('data_protection_notice')}</a>
                        <a href={'/docs/en-privacy-policy.pdf'} className='decoration-none sent content-1'>{t('privacy_policy')}</a>
                        <a href={'/docs/en-aml-policy.pdf'} className='decoration-none sent content-1'>{t('aml_policy')}</a>
                        <a href='mailto:support@pixpalpro.com' className='decoration-none sent content-1'>support@pixpalpro.com</a>
                        <a href='tel:+442070978277' className='decoration-none sent content-1'>+44 207 097 8277</a>
                    </div>

                    <div className='links-other-info'>
                        <div 
                            className='sent content-1'
                            onClick={() => router(RouteNames.MAIN)}
                        > {t('start_trading')} </div>
                        <div 
                            className='sent content-1'
                            onClick={() => router(RouteNames.TRADING_PLATFORMS)}
                        > {t('trading_platforms')} </div>
                        <div 
                            className='sent content-1'
                            onClick={() => router(RouteNames.EARNINGS_CALENDAR)}
                        > {t('earnings_calendar')} </div>
                        <div 
                            className='sent content-1'
                            onClick={() => router(RouteNames.EDU_AND_TOOLS)}
                        > {t('edu_tools')} </div>
                        <div 
                            className='sent content-1'
                            onClick={() => router(RouteNames.AFILLIATES_AND_PARTNERS)}
                        > {t('affiliates_partners')} </div>
                        <div 
                            className='sent content-1'
                            onClick={() => router(RouteNames.ABOUT_US)}
                        > {t('about_us')} </div>
                        <div 
                            className='sent content-1'
                            onClick={() => router(RouteNames.COMPANY_SUPPORT)}
                        > {t('company_support1')} </div>
                    </div>
                </div>
            </div>

            <div className='footer-container-footer'>
                <div className='footer-container-footer-containers'>
                    <div className='about'>
                        <div className='restriction-desc content-6'>
                            {t('footer_about')}<br/><br/>{t('footer_about_under')}
                        </div>
                        <div className='about-images'>
                            <div className='about-images-item ta-left'>
                                <img src={btc} alt='' />
                            </div>
                            <div className='about-images-item ta-left'>
                                <img src={mastercard} alt='' />
                            </div>
                            <div className='about-images-item ta-center'>
                                <img src={visa} alt='' />
                            </div>
                            <div className='about-images-item ta-right'>
                                <img src={wire_transfer} alt='' />
                            </div>
                        </div>
                    </div>
                    <div className='restriction'>
                        <div className='restriction-header content-6'>
                            {t('regional_restrictions')}
                        </div>
                        <div className='restriction-desc content-6'>
                            {t('footer_text_2')}
                        </div>
                    </div>

                    <div className='warning'>
                        <div className='warning-header content-6'>
                            {t('risk_warning')}
                        </div>
                        <div className='warning-desc content-6'>
                            {t('footer_text_3')}
                        </div>

                        <div className='copyright content-1'>
                            {t('copyright')}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Footer;
