import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import NavBar from '../../components/NavBar/NavBar';
import NavBarMobile from '../../components/NavBarMobile/NavBarMobile';
import NewGeneration from '../../components/NewGeneration/NewGeneration';
import RegularBrokers from '../../components/RegularBrokers/RegularBrokers';
import StartScreen from '../../components/StartScreen/StartScreen';
import TabsCFDS from '../../components/TabsCFDS/TabsCFDS';
import UtipTrading from '../../components/UtipTrading/UtipTrading';
import './TradingPlatforms.scss';

const TradingPlatforms = () => {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0,0);
    }, [pathname]);
    return (
        <div>
            <div className='bg'>
              <div className='nb-desktop'>
                <NavBar selPage={'TradingPlatform'} /> 
              </div>
              <div className='nb-mobile'>
                <NavBarMobile selPage={'TradingPlatform'} />
              </div>
              <StartScreen content={'planet'}  />
            </div>
            <TabsCFDS content='sign_up_now' />
            <RegularBrokers />
            <UtipTrading />
            <NewGeneration />
            <Footer styles={'mt-100'} />
        </div>
    );
};

export default TradingPlatforms;
