import classNames from 'classnames';
import { useTranslation } from "react-i18next";
import { useNavigate, Link } from 'react-router-dom';
import { RouteNames } from '../../router';
import logo from '../../assets/pixpal_logo.png';
import logo_dark from '../../assets/pixpal_logo_dark.png';
import { useEffect, useState } from 'react';
import i18n from "i18next";
import Dropdown from 'react-dropdown';
import Cookies from 'js-cookie';
import './NavBarMobile.scss';

const languages = [
    { value: 'ru', label: 'Ru' },
    { value: 'en', label: 'En' },
    { value: 'es', label: 'Es' },
    { value: 'it', label: 'It' },
    { value: 'de', label: 'De' },
    { value: 'pl', label: 'Pl' },
    { value: 'ro', label: 'Ro' },
    { value: 'cz', label: 'Cz' },
];

const NavBarMobile = ({ content, selPage }) => {
    const router = useNavigate();
    const { t } = useTranslation();
    const [ selectedPage, setSelectedPage ] = useState('StartTrading');
    const [ isMenuOpen, setIsMenuOpen ] = useState(false);
    const [ defaultOption, setDefaultOption ] = useState(Cookies.get('i18next'));
    const menuClasses = classNames({
        'open': isMenuOpen === true,
        'close': isMenuOpen === false,
    });
    const menuContentClasses = classNames({
        'visible': isMenuOpen === true,
        'hidden': isMenuOpen === false,
    });
    // eslint-disable-next-line
    useEffect(() => {
        setDefaultOption(Cookies.get('i18next'));
    });
    useEffect(() => {
        setSelectedPage(selPage);
        // eslint-disable-next-line
    }, []);
    
    useEffect(() => {
        if (content === 'dark') {
            document.documentElement.style.setProperty('--bgColor', '#3F778A');
            document.documentElement.style.setProperty('--color', 'white');
            document.documentElement.style.setProperty('--login-color', 'black');
            document.documentElement.style.setProperty('--login-bg', 'white');
            document.documentElement.style.setProperty('--login-box-shadow', '0px 4px 6px #2F5C6B');
            document.documentElement.style.setProperty('--burger-color', 'white');
        } else {
            document.documentElement.style.setProperty('--bgColor', 'transperent');
            document.documentElement.style.setProperty('--color', '#000000');
            document.documentElement.style.setProperty('--login-color', 'white');
            document.documentElement.style.setProperty('--login-bg', '#3F778A');
            document.documentElement.style.setProperty('--login-box-shadow', 'none');
            document.documentElement.style.setProperty('--burger-color', '#3F778A');
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (isMenuOpen === true) {
            document.body.classList.toggle('overflow-hidden');
        } else {
            document.body.classList.remove('overflow-hidden');
        }
    }, [ isMenuOpen ]);

    const burgerFun = () => {
        let brg = document.getElementById('nav-icon4');
        brg.classList.toggle('open');
        setIsMenuOpen(value => !value)

        let root = document.getElementById('root');
        root.classList.toggle('overflow-hidden');
    }

    return (
        <div className='navbar'>
            <div className='navbar-logo'>
                <img
                    className='navbar-logo-icon'
                    height={50}
                    src={content === 'dark' ? logo : logo_dark}
                    alt='logo'
                    onClick={() => router(RouteNames.MAIN)}
                />
            </div>

            <div className='navbar-tools'>
                <div className='navbar-tools-item lang'>
                    <Dropdown placeholderClassName={content === 'dark' ? 'c-white' : 'c-black'} options={languages} onChange={(e) => i18n.changeLanguage(e.value)} value={defaultOption} placeholder='EN' />
                </div>
                <a className={`navbar-tools-item login decoration-none ${content === 'dark' ? 'btn-anim': 'btn-anim-dark'}`} href='https://myaccount.pixpal.tech/login'>
                    {t('login')}
                </a>

                <div className={`navbar-tools-item menu menu-${menuClasses}`}>
                    <div id="nav-icon4" onClick={burgerFun}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <div className={`menu-content menu-content-${menuContentClasses}`}>
                        <div className='menu-content-header'>
                            <img height={50} src={logo} alt='logo_black' />
                        </div>
                        <div className='burger-lang'>
                            <Dropdown placeholderClassName={`c-white`} options={languages} onChange={(e) => i18n.changeLanguage(e.value)} value={defaultOption} placeholder='EN' />
                        </div>
                        <div className='menu-content-content'>
                            <div className='menu-content-content-item'>
                                <Link 
                                    to={RouteNames.MAIN} 
                                    className={`
                                        decoration-none c-white hov
                                        ${selectedPage === 'StartTrading' ? 'selected-page' : ''}
                                    `}
                                >{t('start_trading')}</Link>
                            </div>
                            <div className='menu-content-content-item'> 
                                <Link 
                                    to={RouteNames.TRADING_PLATFORMS} 
                                    className={`
                                        decoration-none c-white hov
                                        ${selectedPage === 'TradingPlatform' ? 'selected-page' : ''}
                                    `}
                                >{t('trading_platforms')}</Link>
                            </div>
                            <div className='menu-content-content-item'> 
                                <Link 
                                    to={RouteNames.EARNINGS_CALENDAR} 
                                    className={`
                                        decoration-none c-white hov 
                                        ${selectedPage === 'EarningsCalendar' ? 'selected-page' : ''}
                                    `}
                                >{t('earnings_calendar')}</Link>
                            </div>
                             <div className='menu-content-content-item'> 
                                <Link 
                                    to={RouteNames.EDU_AND_TOOLS} 
                                    className={`
                                        decoration-none c-white hov
                                        ${selectedPage === 'EduAndTools' ? 'selected-page' : ''}
                                    `}
                                >{t('edu_tools')}</Link>
                            </div>
                            <div className='menu-content-content-item'> 
                                <Link 
                                    to={RouteNames.AFILLIATES_AND_PARTNERS} 
                                    className={`
                                        decoration-none c-white hov
                                        ${selectedPage === 'AffiliatesAndParthners' ? 'selected-page' : ''}
                                    `}
                                >{t('affiliates_partners')}</Link>
                            </div>
                            <div className='menu-content-content-item'> 
                                <Link 
                                    to={RouteNames.ABOUT_US} 
                                    className={`
                                        decoration-none c-white hov
                                        ${selectedPage === 'AboutUs' ? 'selected-page' : ''}
                                    `}
                                >{t('about_us')}</Link>
                            </div>
                            <div className='menu-content-content-item'> 
                                <Link 
                                    to={RouteNames.COMPANY_SUPPORT} 
                                    className={`
                                        decoration-none c-white hov
                                        ${selectedPage === 'CompanyAndSupport' ? 'selected-page' : ''}
                                    `}
                                >{t('company_support1')}</Link>
                            </div>
                        </div>
                        <div className='menu-content-footer'>
                        </div>

                        <div className='menu-content-login'>
                            <a className='decoration-none login-mobile decoration-none btn-anim-dark' href='https://myaccount.pixpal.tech/login'>
                                {t('login')}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default NavBarMobile;
