import { useTranslation } from "react-i18next";
import './BannerComponent.scss';

const BannerComponent = () => {
  const { t } = useTranslation()
  return (
    <div className="banner__wrap"> 
      <div className="col-12 col-md-6">
        <div className="pr-5"> 
          <h1 className="banner_title">
            {t('about_us')} <br/>
            <span className="banner__title__span">{t('who_is_pixpal')}</span>
          </h1>
          <p className="banner__text">{t('about_us_banner_text_1')}</p>
          <p className="banner__text">{t('about_us_banner_text_2')}</p>
          <p className="banner__text">{t('about_us_banner_text_3')}</p>
        </div>
      </div>
      <div className="col-md-6 col-12">
        <div className="d-flex justify-content-center flex-column h-100 align-items-start">
          <h1 className="banner_title">
            <span className="banner__title__span">{t('our_values')}</span>
          </h1>
          <p className="banner__text">{t('our_values_text_1')}</p>
          <a href="https://myaccount.pixpal.tech/signup" className="banner__link btn-anim-dark">{t('open_account')}</a>
        </div>
      </div>
    </div>
  );
};

export default BannerComponent;
