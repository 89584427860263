import NavBar from '../../components/NavBar/NavBar';
import Footer from '../../components/Footer/Footer';
import NavBarMobile from "../../components/NavBarMobile/NavBarMobile";
import BannerComponent from "../../components/CompanySupport/Banner/BannerComponent";
import FormComponent from "../../components/CompanySupport/Form/FormComponent";
import AboutCompanyComponent from "../../components/CompanySupport/AboutCompany/AboutCompanyComponent";
import DifferBrokersComponent from "../../components/CompanySupport/DifferBrokers/DifferBrokersComponent";

import {useEffect} from "react";
import { useLocation } from 'react-router-dom';
import './CompanySupport.scss';

const StartTrading = () => {  
  const { pathname } = useLocation(); 
  useEffect(() => { 
    window.scrollTo(0,0);
  }, [pathname]);
  return (
    <div>
      <div className='nb-desktop'>
        <NavBar selPage={'CompanyAndSupport'} /> 
      </div>
      <div className='nb-mobile'>
        <NavBarMobile selPage={'CompanyAndSupport'}/>
      </div>
      <div className={'container'} >
        <BannerComponent />
        <AboutCompanyComponent />
        <DifferBrokersComponent />
      </div>
      <FormComponent />
      <Footer />
    </div>
  );
};

export default StartTrading;
