import NavBar from '../../components/NavBar/NavBar';
import NavBarMobile from "../../components/NavBarMobile/NavBarMobile";
import BannerComponent from '../../components/AboutUs/Banner/BannerComponent.js';
import OurValuesComponent from '../../components/AboutUs/OurValues/OurValuesComponent';
import Footer from '../../components/Footer/Footer';
import './AboutPage.scss';
import {useEffect} from "react";
import { useLocation } from 'react-router-dom';
import StandsFor from '../../components/StandsFor/StandsFor';

const StartTrading = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0,0);
  }, [pathname]);
  return (
    <div>
      <div className='nb-desktop'>
        <NavBar selPage={'AboutUs'} /> 
      </div>
      <div className='nb-mobile'>
        <NavBarMobile selPage={'AboutUs'} />
      </div>
      <div className={'container'}>
        <BannerComponent />
        <OurValuesComponent />
      </div>
      {/* <WhatPixpalComponent /> */}
      <StandsFor />
      <Footer />
    </div>
  );
};

export default StartTrading;
