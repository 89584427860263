/*eslint unicode-bom: ["error", "always"]*/
export const table = [
    {
        "Logo": "",
        "Full Name": "Abbvie Inc",
        "Code": "ABBV",
        "Date": "04.11.2022",
        "Forecast": 3.6,
        "Previous period": 3.33,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Acciones Iberdrola",
        "Code": "IBE",
        "Date": "26.10.2022",
        "Forecast": 0.14,
        "Previous period": 0.14,
        "Region": "stocks-european"
    }, 
    {
        "Logo": "",
        "Full Name": "Activision Blizzard Inc",
        "Code": "ATVI",
        "Date": "03.11.2022",
        "Forecast": 0.51,
        "Previous period": 0.72,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Adidas",
        "Code": "ADDS",
        "Date": "09.11.2022",
        "Forecast": 2.84,
        "Previous period": 2.34,
        "Region": "stocks-european"
    },
    {
        "Logo": "",
        "Full Name": "Adobe",
        "Code": "ADBE",
        "Date": "15.12.2022",
        "Forecast": 3.5,
        "Previous period": 3.2,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Advanced Micro Devices",
        "Code": "AMD",
        "Date": "25.10.2022",
        "Forecast": 1.05,
        "Previous period": 0.73,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Airbus",
        "Code": "AIR",
        "Date": "28.10.2022",
        "Forecast": 0.98,
        "Previous period": 0.51,
        "Region": "stocks-european"
    },
    {
        "Logo": "",
        "Full Name": "Al-Rajhi Bank",
        "Code": 1120,
        "Date": "17.10.2022",
        "Forecast": 1.05,
        "Previous period": 0.95,
        "Region": "stocks-arabic"
    },
    {
        "Logo": "",
        "Full Name": "Alibaba",
        "Code": "BABA",
        "Date": "04.11.2022",
        "Forecast": 1.65,
        "Previous period": 1.74,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Alinma Bank",
        "Code": 1150,
        "Date": "20.10.2022",
        "Forecast": 0.49,
        "Previous period": 0.36,
        "Region": "stocks-arabic"
    },
    {
        "Logo": "",
        "Full Name": "Alphabet Inc (Google) Class C",
        "Code": "GOOG",
        "Date": "25.10.2022",
        "Forecast": 1.28,
        "Previous period": 1.4,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Amadeus IT Group, S.A",
        "Code": "AMS",
        "Date": "10.11.2022",
        "Forecast": 0.42,
        "Previous period": 0.05,
        "Region": "stocks-european"
    },
    {
        "Logo": "",
        "Full Name": "Amazon",
        "Code": "AMZN",
        "Date": "27.10.2022",
        "Forecast": 0.22,
        "Previous period": 0.31,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "American Express",
        "Code": "AXP",
        "Date": "21.10.2022",
        "Forecast": 2.4,
        "Previous period": 2.27,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "American Outdoor Brands",
        "Code": "AOUT",
        "Date": "09.12.2022",
        "Forecast": 0.22,
        "Previous period": 0.58,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Amgen Inc",
        "Code": "AMGN",
        "Date": "01.11.2022",
        "Forecast": 4.45,
        "Previous period": 4.67,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Apple",
        "Code": "AAPL",
        "Date": "27.10.2022",
        "Forecast": 1.26,
        "Previous period": 1.24,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Arab National Bank",
        "Code": 1080,
        "Date": "02.11.2022",
        "Forecast": 0.49,
        "Previous period": 0.44,
        "Region": "stocks-arabic",
    },
    {
        "Logo": "",
        "Full Name": "Arbor Realty Trust Inc",
        "Code": "ABR",
        "Date": "28.10.2022",
        "Forecast": 0.43,
        "Previous period": 0.51,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "AstraZeneca plc",
        "Code": "AZN",
        "Date": "10.11.2022",
        "Forecast": 0.77,
        "Previous period": 0.54,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Aurora Cannabis Inc",
        "Code": "ACB",
        "Date": "14.11.2022",
        "Forecast": -0.09,
        "Previous period": -0.05,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Banco Bilbao Vizcaya Argentaria, S.A",
        "Code": "BBVA",
        "Date": "28.10.2022",
        "Forecast": 0.23,
        "Previous period": 0.2,
        "Region": "stocks-european"
    },
    {
        "Logo": "",
        "Full Name": "Banco Santander S.A",
        "Code": "SAN",
        "Date": "26.10.2022",
        "Forecast": 0.14,
        "Previous period": 0.12,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Banque Saudi Fransi",
        "Code": 1050,
        "Date": "26.10.2022",
        "Forecast": 0.73,
        "Previous period": 0.71,
        "Region": "stocks-arabic"
    },
    {
        "Logo": "",
        "Full Name": "Barclays plc ord",
        "Code": "BARC",
        "Date": "26.10.2022",
        "Forecast": 0.08,
        "Previous period": 0.09,
        "Region": "stocks-european"
    },
    {
        "Logo": "",
        "Full Name": "Barrick Gold Corporation",
        "Code": "GOLD",
        "Date": "26.10.2022",
        "Forecast": 0.2,
        "Previous period": 0.24,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Bay.motoren Werke AG ST",
        "Code": "BMW",
        "Date": "03.11.2022",
        "Forecast": 3.6,
        "Previous period": 3.89,
        "Region": "stocks-european"
    },
    {
        "Logo": "",
        "Full Name": "Beyond Meat Inc",
        "Code": "BYND",
        "Date": "31.10.2022",
        "Forecast": -1.13,
        "Previous period": -0.87,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "BHP group ltd",
        "Code": "BHP",
        "Date": "20.02.2023",
        "Forecast": 2.9,
        "Previous period": 3.3,
        "Region": "stocks-european"
    },
    {
        "Logo": "",
        "Full Name": "Boeing CO",
        "Code": "BA",
        "Date": "26.10.2022",
        "Forecast": 0.26,
        "Previous period": -0.6,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Booking Holding INC",
        "Code": "BKNG",
        "Date": "02.11.2022",
        "Forecast": 49.51,
        "Previous period": 37.7,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Canopy Growth Corporation",
        "Code": "CGC",
        "Date": "09.11.2022",
        "Forecast": -0.19,
        "Previous period": -0.02,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Carnival Corp",
        "Code": "CCL",
        "Date": "21.12.2022",
        "Forecast": -0.76,
        "Previous period": -1.72,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Chart Industries Inc",
        "Code": "GTLS",
        "Date": "20.10.2022",
        "Forecast": 1.33,
        "Previous period": 0.55,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Chevron Corporation",
        "Code": "CVX",
        "Date": "28.10.2022",
        "Forecast": 4.89,
        "Previous period": 2.96,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "China Railway Group Limited",
        "Code": 601390,
        "Date": "03.11.2022",
        "Forecast": 0.32,
        "Previous period": 0.28,
        "Region": "stocks-asian"
    },
    {
        "Logo": "",
        "Full Name": "Citigroup Inc",
        "Code": "C",
        "Date": "14.10.2022",
        "Forecast": 1.53,
        "Previous period": 2.15,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "CocaCola",
        "Code": "KO",
        "Date": "25.10.2022",
        "Forecast": 0.64,
        "Previous period": 0.65,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "CrowdStrike Holdings Inc",
        "Code": "CRWD",
        "Date": "08.12.2022",
        "Forecast": 0.32,
        "Previous period": 0.17,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Dar Al Arkan Real Estate",
        "Code": 4300,
        "Date": "07.11.2022",
        "Forecast": 0.21,
        "Previous period": 0.02,
        "Region": "stocks-arabic"
    },
    {
        "Logo": "",
        "Full Name": "Deutsche Bank AG",
        "Code": "DB",
        "Date": "26.10.2022",
        "Forecast": 0.4,
        "Previous period": 0.36,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "eBay",
        "Code": "EBAY",
        "Date": "26.10.2022",
        "Forecast": 0.93,
        "Previous period": 0.9,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Exxon Mobil Corporation",
        "Code": "XOM",
        "Date": "28.10.2022",
        "Forecast": 3.49,
        "Previous period": 1.58,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Facebook Inc",
        "Code": "FB",
        "Date": "26.10.2022",
        "Forecast": 1.93,
        "Previous period": 3.22,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "FedEx Corp",
        "Code": "FDX",
        "Date": "20.12.2022",
        "Forecast": 2.9,
        "Previous period": 4.83,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Ferrari",
        "Code": "RACE",
        "Date": "02.11.2022",
        "Forecast": 1.13,
        "Previous period": 1.29,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Ford",
        "Code": "F",
        "Date": "26.10.2022",
        "Forecast": 0.39,
        "Previous period": 0.51,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Gazprom Neft",
        "Code": "SIBN",
        "Date": "17.11.2022",
        "Forecast": 41.46,
        "Previous period": 29.62,
        "Region": "stocks-russian"
    },
    {
        "Logo": "",
        "Full Name": "General Electric Co",
        "Code": "GE",
        "Date": "25.10.2022",
        "Forecast": 0.52,
        "Previous period": 0.57,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Gilead Sciences Inc",
        "Code": "GILD",
        "Date": "27.10.2022",
        "Forecast": 1.54,
        "Previous period": 2.65,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Glaxosmithkline adr each",
        "Code": "GSK",
        "Date": "02.11.2022",
        "Forecast": 0.91,
        "Previous period": 1.01,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Goldman Sach Group Inc",
        "Code": "GS",
        "Date": "18.10.2022",
        "Forecast": 8.03,
        "Previous period": 14.93,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "GoPro Inc",
        "Code": "GPRO",
        "Date": "03.11.2022",
        "Forecast": 0.17,
        "Previous period": 0.34,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "HarleyDavidson",
        "Code": "HOG",
        "Date": "25.10.2022",
        "Forecast": 1.42,
        "Previous period": 1.18,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Herbalife Nutrition LTD",
        "Code": "HLF",
        "Date": "31.10.2022",
        "Forecast": 0.78,
        "Previous period": 1.21,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Honeywell International Inc",
        "Code": "HON",
        "Date": "27.10.2022",
        "Forecast": 2.16,
        "Previous period": 2.02,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Industria de dieso textil S.A. Inditex-",
        "Code": "ITX",
        "Date": "14.12.2022",
        "Forecast": 0.42,
        "Previous period": 0.39,
        "Region": "stocks-european"
    },
    {
        "Logo": "",
        "Full Name": "Industrial and Commercial Bank of China",
        "Code": "IDCBY",
        "Date": "02.11.2022",
        "Forecast": 0.88,
        "Previous period": 0.72,
        "Region": "stocks-asian"
    },
    {
        "Logo": "",
        "Full Name": "Inovio Pharmaceuticals Inc",
        "Code": "INO",
        "Date": "09.11.2022",
        "Forecast": -0.29,
        "Previous period": -0.29,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Intel Corp",
        "Code": "INTC",
        "Date": "27.10.2022",
        "Forecast": 0.35,
        "Previous period": 1.71,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "International bus mach corp",
        "Code": "IBM",
        "Date": "19.10.2022",
        "Forecast": 1.79,
        "Previous period": 2.41,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Johnson & Johnson",
        "Code": "JNJ",
        "Date": "18.10.2022",
        "Forecast": 2.52,
        "Previous period": 2.6,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "JPMorgan Chase & CO",
        "Code": "JPM",
        "Date": "14.10.2022",
        "Forecast": 2.93,
        "Previous period": 3.74,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Lockheed Martin Corp",
        "Code": "LMT",
        "Date": "25.10.2022",
        "Forecast": 6.67,
        "Previous period": 2.21,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Louis Vuitton (LVMH)",
        "Code": "MC",
        "Date": "26.01.2023",
        "Forecast": 15,
        "Previous period": 13.36,
        "Region": "stocks-european"
    },
    {
        "Logo": "",
        "Full Name": "Lukoil",
        "Code": "LKOH",
        "Date": "30.11.2022",
        "Forecast": 592.3,
        "Previous period": 324.78,
        "Region": "stocks-russian"
    },
    {
        "Logo": "",
        "Full Name": "Lyft Inc",
        "Code": "LYFT",
        "Date": "01.11.2022",
        "Forecast": 0.06,
        "Previous period": 0.05,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Manchester utd plc new",
        "Code": "MANU",
        "Date": "17.11.2022",
        "Forecast": -0.12,
        "Previous period": -0.1,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Mastercard Incorporated",
        "Code": "MA",
        "Date": "01.11.2022",
        "Forecast": 2.57,
        "Previous period": 2.37,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "McDonald's Corporation",
        "Code": "MCD",
        "Date": "25.10.2022",
        "Forecast": 2.6,
        "Previous period": 2.76,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Medifast Inc",
        "Code": "MED",
        "Date": "08.11.2022",
        "Forecast": 1.85,
        "Previous period": 3.56,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Microsoft Corporation",
        "Code": "MSFT",
        "Date": "26.10.2022",
        "Forecast": 2.33,
        "Previous period": 2.27,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "MMC Norilsk Nickel",
        "Code": "GMKN",
        "Date": "01.03.2023",
        "Forecast": 1.69,
        "Previous period": 1.17,
        "Region": "stocks-russian"
    },
    {
        "Logo": "",
        "Full Name": "Moderna Inc.",
        "Code": "MRNA",
        "Date": "03.11.2022",
        "Forecast": 4.81,
        "Previous period": 7.7,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Moneygram International Inc",
        "Code": "MGI",
        "Date": "04.11.2022",
        "Forecast": 0.13,
        "Previous period": 0.13,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "MTS",
        "Code": "MTSS",
        "Date": "15.11.2022",
        "Forecast": 12.94,
        "Previous period": 4.89,
        "Region": "stocks-russian"
    },
    {
        "Logo": "",
        "Full Name": "National Gas & Industrialization Company",
        "Code": "NFG",
        "Date": "03.11.2022",
        "Forecast": 1.25,
        "Previous period": 0.95,
        "Region": "stocks-arabic"
    },
    {
        "Logo": "",
        "Full Name": "Netflix",
        "Code": "NFLX",
        "Date": "18.10.2022",
        "Forecast": 2.18,
        "Previous period": 3.19,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Newmont Mining Corp.",
        "Code": "NEM",
        "Date": "27.10.2022",
        "Forecast": 0.55,
        "Previous period": 0.6,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Nike",
        "Code": "NKE",
        "Date": "22.12.2022",
        "Forecast": 0.64,
        "Previous period": 0.83,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Nikola Corporation",
        "Code": "NKLA",
        "Date": "03.11.2022",
        "Forecast": -0.36,
        "Previous period": -0.22,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Norwegian Cruise",
        "Code": "NCLH",
        "Date": "02.11.2022",
        "Forecast": -0.68,
        "Previous period": -2.17,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Novavax Inc",
        "Code": "NVAX",
        "Date": "03.11.2022",
        "Forecast": 1.45,
        "Previous period": -4.31,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Nu Skin Enterprises Inc",
        "Code": "NUS",
        "Date": "09.11.2022",
        "Forecast": 0.59,
        "Previous period": 0.97,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Nvidia Corp",
        "Code": "NVDA",
        "Date": "17.11.2022",
        "Forecast": 0.73,
        "Previous period": 1.17,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Okta, Inc",
        "Code": "Okta",
        "Date": "07.12.2022",
        "Forecast": -0.24,
        "Previous period": -0.07,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Oracle Corporation",
        "Code": "ORCL",
        "Date": "15.12.2022",
        "Forecast": 1.17,
        "Previous period": 1.21,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "PayPal Holdings Inc",
        "Code": "PYPL",
        "Date": "20.10.2022",
        "Forecast": 0.96,
        "Previous period": 1.11,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "PetroChina Company Limited",
        "Code": 601857,
        "Date": "01.11.2022",
        "Forecast": 0.18,
        "Previous period": 0.12,
        "Region": "stocks-asian"
    },
    {
        "Logo": "",
        "Full Name": "Pfizer Inc",
        "Code": "PFE",
        "Date": "01.11.2022",
        "Forecast": 1.5,
        "Previous period": 1.34,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Philip Morris International Inc",
        "Code": "PM",
        "Date": "20.10.2022",
        "Forecast": 1.37,
        "Previous period": 1.58,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Pllc Yandex N.V. Class a shs",
        "Code": "YNDX",
        "Date": "27.10.2022",
        "Forecast": 2.64,
        "Previous period": 5.12,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Procter & Gamble",
        "Code": "PG",
        "Date": "19.10.2022",
        "Forecast": 1.56,
        "Previous period": 1.61,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "QIWI",
        "Code": "QIWI",
        "Date": "23.11.2022",
        "Forecast": 31.51,
        "Previous period": 43.32,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Renault",
        "Code": "RNO",
        "Date": "21.02.2023",
        "Forecast": 4.36,
        "Previous period": 1.95,
        "Region": "stocks-european"
    },
    {
        "Logo": "",
        "Full Name": "RioTinto",
        "Code": "RIO",
        "Date": "22.02.2023",
        "Forecast": 3.58,
        "Previous period": 5.69,
        "Region": "stocks-european"
    },
    {
        "Logo": "",
        "Full Name": "RiyadBank",
        "Code": 1010,
        "Date": "05.10.2022",
        "Forecast": 0.59,
        "Previous period": 0.51,
        "Region": "stocks-arabic"
    },
    {
        "Logo": "",
        "Full Name": "Rosneft oil co",
        "Code": "ROSN",
        "Date": "10.11.2022",
        "Forecast": 70.48,
        "Previous period": 33.77,
        "Region": "stocks-russian",
    },
    {
        "Logo": "",
        "Full Name": "Royal Caribbean Group",
        "Code": "RCL",
        "Date": "28.10.2022",
        "Forecast": 0.19,
        "Previous period": -4.91,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Salesforce.com Inc",
        "Code": "CRM",
        "Date": "29.11.2022",
        "Forecast": 1.22,
        "Previous period": 1.27,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Saudi Basic Industries Corporation",
        "Code": 2010,
        "Date": "24.10.2022",
        "Forecast": 1.48,
        "Previous period": 1.86,
        "Region": "stocks-arabic"
    },
    {
        "Logo": "",
        "Full Name": "Saudi Kayan",
        "Code": 2350,
        "Date": "18.10.2022",
        "Forecast": 0.17,
        "Previous period": 0.44,
        "Region": "stocks-arabic"
    },
    {
        "Logo": "",
        "Full Name": "Sberbank",
        "Code": "SBER",
        "Date": "03.11.2022",
        "Forecast": 13.47,
        "Previous period": 13.18,
        "Region": "stocks-russian"
    },
    {
        "Logo": "",
        "Full Name": "Scotts Miracle-Gro Company",
        "Code": "SMG",
        "Date": "02.11.2022",
        "Forecast": -2,
        "Previous period": -0.82,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "ShotSpotter Inc",
        "Code": "SSTI",
        "Date": "15.11.2022",
        "Forecast": -0.06,
        "Previous period": -0.08,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Siemens ag na O.N",
        "Code": "SIE",
        "Date": "17.11.2022",
        "Forecast": 3.25,
        "Previous period": 1.43,
        "Region": "stocks-european"
    },
    {
        "Logo": "",
        "Full Name": "SNap Inc",
        "Code": "SNAP",
        "Date": "25.10.2022",
        "Forecast": -0.24,
        "Previous period": -0.05,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Sony Corporation",
        "Code": "SONY",
        "Date": "28.10.2022",
        "Forecast": 1.2,
        "Previous period": 1.51,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Southern Copper Corp.",
        "Code": "SCCO",
        "Date": "26.10.2022",
        "Forecast": 0.66,
        "Previous period": 1.12,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Spotify Technology SA",
        "Code": "SPOT",
        "Date": "25.10.2022",
        "Forecast": -0.86,
        "Previous period": 0.01,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Square Inc.",
        "Code": "SQ",
        "Date": "09.11.2022",
        "Forecast": 0.24,
        "Previous period": 0.37,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "SturmRuger",
        "Code": "RGR",
        "Date": "02.11.2022",
        "Forecast": 1.14,
        "Previous period": 1.98,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Target Corp",
        "Code": "TGT",
        "Date": "23.11.2022",
        "Forecast": 2.15,
        "Previous period": 3.03,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Tesla Inc",
        "Code": "TSLA",
        "Date": "19.10.2022",
        "Forecast": 1.04,
        "Previous period": 0.62,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "The Saudi British Bank",
        "Code": 1060,
        "Date": "26.10.2022",
        "Forecast": 0.61,
        "Previous period": 0.43,
        "Region": "stocks-arabic"
    },
    {
        "Logo": "",
        "Full Name": "Toyota Motor Corp.",
        "Code": "TM",
        "Date": "08.11.2022",
        "Forecast": 3.79,
        "Previous period": 3.96,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Verizon",
        "Code": "VZ",
        "Date": "21.10.2022",
        "Forecast": 1.28,
        "Previous period": 1.41,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Visa Inc",
        "Code": "V",
        "Date": "26.10.2022",
        "Forecast": 1.87,
        "Previous period": 1.62,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Volkswagen ag vzo O.N",
        "Code": "VOW3",
        "Date": "28.10.2022",
        "Forecast": 7.38,
        "Previous period": 5.51,
        "Region": "stocks-european"
    },
    {
        "Logo": "",
        "Full Name": "Walmart Inc",
        "Code": "WMT",
        "Date": "15.11.2022",
        "Forecast": 1.31,
        "Previous period": 1.45,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Walt Disney Company",
        "Code": "DIS",
        "Date": "10.11.2022",
        "Forecast": 0.61,
        "Previous period": 0.37,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Western Union Company",
        "Code": "WU",
        "Date": "03.11.2022",
        "Forecast": 0.39,
        "Previous period": 0.63,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Zoom Video Communications Inc",
        "Code": "ZM",
        "Date": "22.11.2022",
        "Forecast": 0.84,
        "Previous period": 1.11,
        "Region": "stocks-american"
    },
    {
        "Logo": "",
        "Full Name": "Zscaler Inc",
        "Code": "ZS",
        "Date": "06.12.2022",
        "Forecast": 0.26,
        "Previous period": 0.14,
        "Region": "stocks-american"
    }
]