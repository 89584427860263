import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import QuaterlyReporting from '../../components/EarningsCalendar/QuaterlyReporting/QuaterlyReporting';
import Footer from '../../components/Footer/Footer';
import NavBar from '../../components/NavBar/NavBar';
import NavBarMobile from '../../components/NavBarMobile/NavBarMobile';
import TradeToday02 from '../../components/TradeToday02/TradeToday02';
import ECStartScreen from '../../components/EarningsCalendar/ECStartScreen/ECStartScreen';
import './EarningsCalendar.scss';

const EarningsCalendar = () => {
    const { pathname } = useLocation();
    useEffect(() => { 
      window.scrollTo(0,0);
    }, [pathname]);
    return (
        <div className='wrapper'>
            <div className='ec-bg'>
                <div className='nb-desktop'>
                    <NavBar selPage={'EarningsCalendar'} /> 
                </div>
                <div className='nb-mobile'>
                    <NavBarMobile selPage={'EarningsCalendar'}/>
                </div>
                <ECStartScreen />
            </div>
            <QuaterlyReporting />
            <TradeToday02 />
            <Footer />
        </div>
    );
};

export default EarningsCalendar;
