import { useTranslation } from "react-i18next";
import * as Scroll from 'react-scroll';
import './WhatIsForex.scss';
const Element = Scroll.Element;

const WhatIsForex = () => {
    const { t } = useTranslation()
    return (
        <div className='wif-container'>
            <Element name='what_is_forex'>
                <div className='wif-container-header h-1'>
                    {t('et_start_table_2')}
                </div>
            </Element>
            <div className='wif-container-content'>
                <div className='wif-container-content-text content-1'>
                    <div className='wif-container-content-text-item'>
                        {t('et_start_table_1_text_1')}
                    </div>
                    <div className='wif-container-content-text-item'>
                        {t('et_start_table_1_text_2')}
                    </div>
                    <div className='wif-container-content-text-item'>
                        {t('et_start_table_1_text_3')}
                    </div>
                </div>
                <div className='wif-container-content-img content-1'>
                    <div className='wif-container-content-text-item'>
                        {t('et_start_table_1_text_4')}
                    </div>
                    <div className='wif-container-content-text-item'>
                        {t('et_start_table_1_text_5')}
                    </div>
                    {/* <img src={image} alt='img' /> */}
                </div>
            </div>
        </div>
    );
};

export default WhatIsForex;
